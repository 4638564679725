<template>
  <div class="breadcrumbs h5 cl-gray">
    <span v-for="link in routes" :key="link.route_link">
      <router-link :to="link.route_link">
        {{ link.name | htmlDecode }}
      </router-link> /
    </span>
    <span class="cl-mine-shaft">
      {{ activeRoute | htmlDecode }}
    </span>
  </div>
</template>

<script>

export default {
  name: 'Breadcrumbs',
  props: {
    routes: {
      type: Array,
      required: true
    },
    activeRoute: {
      type: String,
      required: true
    }
  }
}
</script>
